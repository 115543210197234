import {
    AddWellControlSettingInput,
    ControlSettingFieldsFragment,
    SubjectRole,
} from "../../generated/graphql"
import { WellRunModeType } from "../../types/controlSettingTypes"
import {
    SmartTimerErrorInterface,
    TimerErrorInterface,
    WellControlSmartTimerInterface,
    TimerModeDisplaySummaryInterface,
    WellControlTimerInterface,
    WellControlStateInterface,
} from "../../types/wellControlModalTypes"
import { wellControlsErrorMessages } from "../errorMessages"
import { percentageToRatio } from "../numbers/numbers"
import { objectHasNonUndefinedFields } from "../objects/objects"
import { canRoleUseSmartSettingOverride } from "../rolePermissions/canRole"

const updateTimerState = (
    timerState: WellControlTimerInterface
): TimerModeDisplaySummaryInterface => {
    const { timerCycleHours, timerCycleMinutes, runPercentage } = timerState

    //  Sum totalTimerCyleTime
    const totalTimerCyleTime_minutes = timerCycleHours * 60 + timerCycleMinutes

    // Get totalRunForTime_minutes. ( totalTimerCyleTime_minutes * run ratio ). Round totalRunForTime_minutes to nearest interger.
    const totalRunForTime_minutes = Math.round(
        totalTimerCyleTime_minutes * (runPercentage / 100)
    )

    // Get 'runForTime.hours'.
    const updated_runForTime_hours = Math.floor(totalRunForTime_minutes / 60)

    // Get 'runForTime.minutes'.
    const updated_runForTime_minutes =
        totalRunForTime_minutes - updated_runForTime_hours * 60

    // Get total idleForTime.hours ( Math.floor( totalTimerCyleTime - totalRunForTime_minutes)/60)
    const updated_idleForTime_hours = Math.floor(
        (totalTimerCyleTime_minutes - totalRunForTime_minutes) / 60
    )

    // Get total idleForTime.minutes (totalTimerCyleTime - totalRunForTime_minutes - idle for hours * 60)
    const updated_idleForTime_minutes =
        totalTimerCyleTime_minutes -
        totalRunForTime_minutes -
        updated_idleForTime_hours * 60

    const obj: TimerModeDisplaySummaryInterface = {
        runForTime: {
            hours: updated_runForTime_hours,
            minutes: updated_runForTime_minutes,
        },
        IdleForTime: {
            hours: updated_idleForTime_hours,
            minutes: updated_idleForTime_minutes,
        },
    }

    return obj
}

/**
 * Validates well control timer state based on user input changes.
 *
 * @param newTimerState - Updated timer state from user input changes.
 * @returns Timer state error object that is used to display errors and create disabled states.
 */
const validateTimerInputs = (
    newTimerState: WellControlTimerInterface
): TimerErrorInterface => {
    const newErrorState: TimerErrorInterface = {
        timerCycleHours: undefined,
        timerCycleMinutes: undefined,
        runPercentage: undefined,
    }

    /** Hours  or minutes or run percentage are not a valid number */
    if (
        typeof newTimerState.timerCycleHours === "string" ||
        newTimerState.timerCycleHours < 0
    ) {
        newErrorState.timerCycleHours =
            wellControlsErrorMessages.timerState.invalidTimer
    }

    if (
        typeof newTimerState.timerCycleMinutes === "string" ||
        newTimerState.timerCycleMinutes < 0
    ) {
        newErrorState.timerCycleMinutes =
            wellControlsErrorMessages.timerState.invalidTimer
    }

    if (typeof newTimerState.runPercentage === "string") {
        newErrorState.runPercentage =
            wellControlsErrorMessages.timerState.invalidTimer
    }
    /** Run percentage is not a number 1 - 99, run percentage is not over 14 digits */
    if (
        newTimerState.runPercentage >= 100 ||
        newTimerState.runPercentage < 1 ||
        newTimerState.runPercentage.toString().length > 14
    ) {
        newErrorState.runPercentage =
            wellControlsErrorMessages.timerState.invalidTimer
    }

    if (
        newTimerState.timerCycleHours * 60 + newTimerState.timerCycleMinutes <=
        0
    ) {
        newErrorState.timerCycleHours =
            wellControlsErrorMessages.timerState.invalidTimer
        newErrorState.timerCycleMinutes =
            wellControlsErrorMessages.timerState.invalidTimer
    }

    return newErrorState
}

const validateSmartTimerInputs = (
    newSmartTimerState: WellControlSmartTimerInterface
): SmartTimerErrorInterface => {
    const newErrorState: SmartTimerErrorInterface = {
        smartIdleHours: undefined,
        smartIdleMinutes: undefined,
        targetEmptyRatio: undefined,
    }

    const { smartIdleHours, smartIdleMinutes } = newSmartTimerState

    const smartTimerStateKeys: (keyof WellControlSmartTimerInterface)[] = [
        "smartIdleHours",
        "smartIdleMinutes",
        "targetEmptyRatio",
    ]

    for (const key of smartTimerStateKeys) {
        if (
            typeof newSmartTimerState[key] === "string" ||
            newSmartTimerState[key] < 0
        ) {
            newErrorState[key] =
                wellControlsErrorMessages.smartTimerState.invalidValue
        }
    }

    if (
        newSmartTimerState.targetEmptyRatio < 0.01 ||
        newSmartTimerState.targetEmptyRatio > 0.99
    ) {
        newErrorState.targetEmptyRatio =
            wellControlsErrorMessages.smartTimerState.invalidValue
    }

    if (smartIdleHours * 60 + smartIdleMinutes <= 0) {
        newErrorState.smartIdleHours =
            wellControlsErrorMessages.smartTimerState.invalidValue
        newErrorState.smartIdleMinutes =
            wellControlsErrorMessages.smartTimerState.invalidValue
    }

    return newErrorState
}

/**
 * Determine if submit and previous should be disabled
 *  @param {boolean} mutationLoading graphql mutation loading indicator
 *  @param {boolean} mutationError wellControlState.mutationError state. Set to true when the mutation has an error.
 * @returns true (disable footer) if mutationLoading || mutation error is true. Else return false
 */

const disableWellControlSubmission = (
    mutationLoading: boolean,
    mutationError: boolean
) => {
    if (mutationLoading === true || mutationError === true) {
        return true
    }
    return false
}

/**
 * Disables the run mode change advance based on the selected run mode and error states.
 * @param initialRunMode - The initial run mode.
 * @param wellControlState - The well control state object containing the UI state and timer error states.
 * @returns True if the run mode change advance should be disabled, false otherwise.
 */
const disableRunModeChangeAdvance = (
    initialRunMode: WellRunModeType,
    wellControlState: WellControlStateInterface
): boolean => {
    const {
        UiState: { selectedRunMode },
        timerErrorsState,
        smartTimerErrorsState,
    } = wellControlState

    if (selectedRunMode === "Timer") {
        if (
            wellControlState.timerState.timerCycleHours +
                wellControlState.timerState.timerCycleMinutes ===
            0
        ) {
            /** This if statement is needed to stop a user from submitting a timer with hours and minutes summing  to 0  (before any chagnes are made to the timer).
             After an initial change,  onblur handlers are called,  thus validating the timer state with @function validateTimerInputs
             */
            return true
        }

        const containsErrors = objectHasNonUndefinedFields(timerErrorsState)
        return containsErrors
    }

    if (selectedRunMode === "Smart") {
        const containsErrors = objectHasNonUndefinedFields(
            smartTimerErrorsState
        )
        return containsErrors
    }

    if (selectedRunMode === initialRunMode) {
        return true
    }

    return false
}

const disableHistoryEdit = (
    index: number | undefined,
    controlSettingHistory: ControlSettingFieldsFragment[],
    userRole: SubjectRole
) => {
    if (index === undefined) {
        return true
    }

    const controlSetting = controlSettingHistory[index]

    switch (controlSetting.__typename) {
        case "WellControlSettingOff":
        case "WellControlSettingOn":
        case "WellControlSettingUnspecified":
            return true
        case "WellControlSettingConstantIdle":
            switch (userRole) {
                case SubjectRole.PpoAdmin:
                    return false
                case SubjectRole.OrgAdmin:
                case SubjectRole.OrgMember:
                case SubjectRole.Unknown:
                default:
                    return true
            }
        case "WellControlSettingTimer":
            return false
    }

    //  controlSetting.__typename === 'WellControlSettingTimer'
    return true
}

const getSmartSettingVariablesInput = (
    state: WellControlStateInterface,
    subjectRole: SubjectRole,
    wellId: string
) => {
    const {
        useSmartSettingOverride,
        UiState: { reasonForControlChange },
        smartTimerState: { smartIdleHours, smartIdleMinutes, targetEmptyRatio },
    } = state

    // Only PPO admin can use smart setting override. They must select to do so.
    if (
        useSmartSettingOverride &&
        canRoleUseSmartSettingOverride(subjectRole)
    ) {
        return {
            constantIdle: {
                specific: {
                    wellID: wellId,
                    reason: reasonForControlChange,
                    idle: {
                        duration: {
                            hours: smartIdleHours,
                            minutes: smartIdleMinutes,
                        },
                    },
                    targetEmptyRatio: targetEmptyRatio,
                    // initial run time is an optional parameter and currently not being used. The api will set a default when it is not used.
                },
            },
        }
    } else {
        return {
            constantIdle: {
                empty: {
                    wellID: wellId,
                    reason: reasonForControlChange,
                },
            },
        }
    }
}

/**
 * Returns the input variables for adding a well control setting based on the provided state, subject role, and well ID.
 *
 * @param state - the modal state - implements  WellControlStateInterface
 * @param subjectRole - The role of the subject performing the action.
 * @param wellId - wellId
 * @returns {AddWellControlSettingInput} - The input variables for addWellControlSettingMutation
 *
 */
const getAddWellControlVariablesInput = (
    state: WellControlStateInterface,
    subjectRole: SubjectRole,
    wellId: string
): AddWellControlSettingInput => {
    let controlSettingVariables: AddWellControlSettingInput

    const {
        UiState: { reasonForControlChange, selectedRunMode },
        timerState: { timerCycleHours, timerCycleMinutes, runPercentage },
    } = state

    switch (selectedRunMode) {
        case "Off":
            controlSettingVariables = {
                off: {
                    wellID: wellId,
                    reason: reasonForControlChange,
                },
            }
            break
        case "On":
            controlSettingVariables = {
                on: {
                    wellID: wellId,
                    reason: reasonForControlChange,
                },
            }
            break
        case "Smart":
            controlSettingVariables = getSmartSettingVariablesInput(
                state,
                subjectRole,
                wellId
            )
            break
        case "Timer":
            controlSettingVariables = {
                timer: {
                    wellID: wellId,
                    reason: reasonForControlChange,
                    period: {
                        duration: {
                            hours: timerCycleHours,
                            minutes: timerCycleMinutes,
                        },
                    },
                    runRatio: percentageToRatio(runPercentage),
                },
            }
            break
        default:
            throw new Error("Handle submit well controls unexpected behavior.")
    }

    return controlSettingVariables
}

export {
    updateTimerState,
    validateTimerInputs,
    disableWellControlSubmission,
    disableRunModeChangeAdvance,
    validateSmartTimerInputs,
    disableHistoryEdit,
    getAddWellControlVariablesInput,
}
