import { FunctionComponent, useContext, createContext, useState } from "react"
// types
import {
    SmartTimerErrorInterface,
    TimerErrorInterface,
    WellControlSmartTimerInterface,
    WellControlStateInterface,
    WellControlTimerInterface,
    WellControlUiStateInterface,
    WellControlsContextInterface,
    WellControlsProviderInterface,
} from "../types/wellControlModalTypes"
//utils
import {
    validateSmartTimerInputs,
    validateTimerInputs,
} from "../util/wellControlUtil/wellControlsUtils"
import {
    parseInputToFloat,
    parseNumberInput,
    parseThreeDigitNumberInput,
    parseTwoDigitNumberInput,
    setInputMax,
} from "../util/InputValidation/inputValidation"
import { getWellControlSettingFragmentFieldsUnion } from "../util/ControlSettingUtil"
// graphql
import {
    ControlSettingConstantIdleFieldsFragment,
    ControlSettingFieldsFragment,
    ControlSettingTimerFieldsFragment,
} from "../generated/graphql"
import { objectHasNonUndefinedFields } from "../util/objects/objects"
import { ratioToPercentage } from "../util/numbers/numbers"

// Create context
const WellControlsContext = createContext<
    undefined | WellControlsContextInterface
>(undefined)

// Create provider
const WellControlsProvider: FunctionComponent<
    WellControlsProviderInterface
> = ({ children }) => {
    const initialUiState: WellControlUiStateInterface = {
        wellControlModalView: "Control",
        selectedRunMode: "On",
        wellControlConfirmation: false,
        reasonForControlChange: "",
        mutationError: false,
        selectedHistoryIndex: undefined,
    }

    const initialTimerState: WellControlTimerInterface = {
        timerCycleHours: 0,
        timerCycleMinutes: 0,
        runPercentage: 50,
    }

    const initialTimerErrorState: TimerErrorInterface = {
        timerCycleHours: undefined,
        timerCycleMinutes: undefined,
        runPercentage: undefined,
    }

    const initialSmartTimerState: WellControlSmartTimerInterface = {
        smartIdleHours: 0,
        smartIdleMinutes: 0,
        targetEmptyRatio: 0,
    }

    const initialSmartTimerErrorState: SmartTimerErrorInterface = {
        smartIdleHours: undefined,
        smartIdleMinutes: undefined,
        targetEmptyRatio: undefined,
    }

    const [wellControlState, setWellControlState] =
        useState<WellControlStateInterface>({
            UiState: initialUiState,
            timerState: initialTimerState,
            timerErrorsState: initialTimerErrorState,
            useSmartSettingOverride: false,
            smartTimerState: initialSmartTimerState,
            smartTimerErrorsState: initialSmartTimerErrorState,
        })

    // Context methods - used to control well controls modal.
    const createNewTimerStateObject = (
        controlSettingFragmentFields: ControlSettingTimerFieldsFragment
    ) => {
        // convert incoming ratio to percentage
        const runPercentage = ratioToPercentage(
            controlSettingFragmentFields.runRatio
        )

        const timerState: WellControlTimerInterface = {
            timerCycleHours: controlSettingFragmentFields.period.hours,
            timerCycleMinutes: controlSettingFragmentFields.period.minutes,
            runPercentage: runPercentage,
        }
        return timerState
    }

    const createNewSmartTimerStateObject = (
        controlSettingFragmentFields: ControlSettingConstantIdleFieldsFragment
    ) => {
        const smartTimerState: WellControlSmartTimerInterface = {
            smartIdleHours: controlSettingFragmentFields.constantIdle.hours,
            smartIdleMinutes: controlSettingFragmentFields.constantIdle.minutes,
            targetEmptyRatio: controlSettingFragmentFields.targetEmptyRatio,
        }
        return smartTimerState
    }

    /**
     * After the querying of well control history in well control index, the use effect  calls this function to set the state of the modal based on the
     * most recent control setting
     */
    const setWellControlStateOnInitialQuery = (
        controlSettingFieldsFragment: ControlSettingFieldsFragment
    ) => {
        const { runMode, controlSettingFragmentFields } =
            getWellControlSettingFragmentFieldsUnion(
                controlSettingFieldsFragment
            )
        if (
            controlSettingFragmentFields.__typename ===
            "WellControlSettingTimer"
        ) {
            const newTimerState = createNewTimerStateObject(
                controlSettingFragmentFields
            )

            setWellControlState({
                ...wellControlState,
                UiState: {
                    ...wellControlState.UiState,
                    selectedRunMode: runMode,
                },
                timerState: newTimerState,
            })
            return
        } else if (
            controlSettingFragmentFields.__typename ===
            "WellControlSettingConstantIdle"
        ) {
            const newSmartTimerState = createNewSmartTimerStateObject(
                controlSettingFragmentFields
            )

            setWellControlState({
                ...wellControlState,
                UiState: {
                    ...wellControlState.UiState,
                    selectedRunMode: runMode,
                },
                smartTimerState: newSmartTimerState,
            })
            return
        } else {
            setWellControlState({
                ...wellControlState,
                UiState: {
                    ...wellControlState.UiState,
                    selectedRunMode: runMode,
                },
            })
        }
    }

    const handleWellControlSettingChange = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (
            // This if statement blocks the component from rerednering when the user is clicking on the same navigational control. (Control => Control)
            (e.target as HTMLElement).id ===
            wellControlState.UiState.wellControlModalView
        ) {
            return
        }
        const newModalView: "Control" | "History" =
            wellControlState.UiState.wellControlModalView === "Control"
                ? "History"
                : "Control"

        setWellControlState({
            ...wellControlState,
            UiState: {
                ...wellControlState.UiState,
                wellControlModalView: newModalView,
            },
        })
    }

    const handleRunModeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        switch (e.target.value) {
            case "Off":
            case "On":
                setWellControlState({
                    ...wellControlState,
                    UiState: {
                        ...wellControlState.UiState,
                        selectedRunMode: e.target.value,
                    },
                })

                break
            case "Timer":
                setWellControlState({
                    ...wellControlState,
                    UiState: {
                        ...wellControlState.UiState,
                        selectedRunMode: e.target.value,
                    },
                })
                break
            case "Smart":
                setWellControlState({
                    ...wellControlState,
                    UiState: {
                        ...wellControlState.UiState,
                        selectedRunMode: e.target.value,
                    },
                })
                break
            default:
                throw new Error(
                    "Well Controls Index. handleRunModeChange unexpected behavior."
                )
        }
    }

    const handleWellControlTimerChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        timerState: WellControlTimerInterface,
        timerErrorsState: TimerErrorInterface
    ) => {
        const { id } = e.target

        let newInput: number | string = 0
        if (id === "timerCycleHours") {
            newInput = parseThreeDigitNumberInput(e)
            newInput = setInputMax(newInput, 168)
        } else if (id === "timerCycleMinutes") {
            newInput = parseTwoDigitNumberInput(e)
            newInput = setInputMax(newInput, 59)
        } else {
            /** id === runPercentage */
            newInput = parseInputToFloat(e)
            newInput = setInputMax(newInput, 99.9999)
        }

        /** Build new timer state. */
        const updatedTimerState: WellControlTimerInterface = {
            ...timerState,
            [e.target.id]: newInput,
        }

        // if timer state has errors, update timerErrorState onChange of inputs.
        const hasErrors = objectHasNonUndefinedFields(timerErrorsState)
        if (hasErrors) {
            // need to see if was fixed
            const updatedTimerErrorState =
                validateTimerInputs(updatedTimerState)

            setWellControlState({
                ...wellControlState,
                timerState: updatedTimerState,
                timerErrorsState: updatedTimerErrorState,
            })
        } else {
            setWellControlState({
                ...wellControlState,
                timerState: updatedTimerState,
            })
        }
    }

    const handleWellControlTimerBlur = () => {
        const updatedTimerErrorState = validateTimerInputs(
            wellControlState.timerState
        )

        setWellControlState({
            ...wellControlState,
            timerErrorsState: updatedTimerErrorState,
        })
    }

    const handleSmartTimerChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        smartTimerState: WellControlSmartTimerInterface
    ) => {
        let newInput: string | number
        /** Parse input. */
        if (e.target.id === "targetEmptyRatio") {
            newInput = parseInputToFloat(e)
        } else {
            newInput = parseNumberInput(e)
        }
        /** Update smart timer state. */
        const updatedSmartTimerState: WellControlSmartTimerInterface = {
            ...smartTimerState,
            [e.target.id]: newInput as number,
        }

        setWellControlState({
            ...wellControlState,
            smartTimerState: updatedSmartTimerState,
        })
    }

    const handleSmartTimerBlur = () => {
        /** Update smart timer error state */
        const updatedSmartTimerErrorsState = validateSmartTimerInputs(
            wellControlState.smartTimerState
        )

        setWellControlState({
            ...wellControlState,
            smartTimerErrorsState: updatedSmartTimerErrorsState,
        })
    }

    // validate timer states upon submission and set confirmation to true
    const handleApplyRunModeChange = () => {
        setWellControlState({
            ...wellControlState,
            UiState: {
                ...wellControlState.UiState,
                wellControlConfirmation: true,
            },
        })
    }

    // Navigate back from well controls submission back to well controls edit. Clear reasonForControlChange.
    const handleExitSubmitControlSettingChange = () => {
        setWellControlState({
            ...wellControlState,
            UiState: {
                ...wellControlState.UiState,
                wellControlConfirmation: false,
                reasonForControlChange: "",
            },
        })
    }

    // Allows well control confirmation to note reason for control change.
    const handleReasonForControlChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setWellControlState({
            ...wellControlState,
            UiState: {
                ...wellControlState.UiState,
                reasonForControlChange: e.target.value,
            },
        })
    }

    const handleSelectHistoryChange = (index: number) => {
        // user clicks the history setting that is selected to 'unselect the history setting'
        if (wellControlState.UiState.selectedHistoryIndex === index) {
            setWellControlState({
                ...wellControlState,
                UiState: {
                    ...wellControlState.UiState,
                    selectedHistoryIndex: undefined,
                },
            })
        }
        // user clicks new history setting
        else {
            setWellControlState({
                ...wellControlState,
                UiState: {
                    ...wellControlState.UiState,
                    selectedHistoryIndex: index,
                },
            })
        }
    }

    const handleApplyControlSettingHistory = (
        index: number | undefined,
        historyArray: ControlSettingFieldsFragment[]
    ) => {
        if (index === undefined) {
            throw new Error(
                " HandleApplyControlSettingHistory. Apply history footer should have been disabled, and place holder control settings (per UI design) should not be clickable. "
            )
        }
        const controlSetting = historyArray[index]

        const { controlSettingFragmentFields, runMode } =
            getWellControlSettingFragmentFieldsUnion(controlSetting)

        if (
            controlSettingFragmentFields.__typename ===
            "WellControlSettingTimer"
        ) {
            const newTimerState = createNewTimerStateObject(
                controlSettingFragmentFields
            )

            setWellControlState({
                ...wellControlState,
                UiState: {
                    ...wellControlState.UiState,
                    selectedHistoryIndex: undefined,
                    selectedRunMode: runMode,
                    wellControlConfirmation: true,
                    wellControlModalView: "History",
                },
                timerState: newTimerState,
            })
            return
        }

        if (
            controlSettingFragmentFields.__typename ===
            "WellControlSettingConstantIdle"
        ) {
            const newSmartTimerState = createNewSmartTimerStateObject(
                controlSettingFragmentFields
            )

            setWellControlState({
                ...wellControlState,
                UiState: {
                    ...wellControlState.UiState,
                    selectedHistoryIndex: undefined,
                    selectedRunMode: runMode,
                    wellControlConfirmation: true,
                    wellControlModalView: "History",
                },
                smartTimerState: newSmartTimerState,
            })
            return
        }

        setWellControlState({
            ...wellControlState,
            UiState: {
                ...wellControlState.UiState,
                selectedHistoryIndex: undefined,
                selectedRunMode: runMode,
                wellControlConfirmation: true,
                wellControlModalView: "History",
            },
        })
    }

    const handleApplyControlSettingEdit = (
        index: number | undefined,
        historyArray: ControlSettingFieldsFragment[]
    ) => {
        if (index === undefined) {
            throw new Error(
                " Apply history footer should have been disabled. Only control settings with timer or smart timer should be allowed to be edited.  "
            )
        }
        const controlSetting = historyArray[index]
        const { controlSettingFragmentFields, runMode } =
            getWellControlSettingFragmentFieldsUnion(controlSetting)

        if (
            controlSettingFragmentFields.__typename ===
            "WellControlSettingTimer"
        ) {
            const newTimerState = createNewTimerStateObject(
                controlSettingFragmentFields
            )

            setWellControlState({
                ...wellControlState,
                UiState: {
                    ...wellControlState.UiState,
                    selectedHistoryIndex: undefined,
                    selectedRunMode: runMode,
                    wellControlConfirmation: false,
                    wellControlModalView: "Control",
                },
                timerState: newTimerState,
            })
        }

        if (
            controlSettingFragmentFields.__typename ===
            "WellControlSettingConstantIdle"
        ) {
            const newSmartTimerState = createNewSmartTimerStateObject(
                controlSettingFragmentFields
            )

            setWellControlState({
                ...wellControlState,
                UiState: {
                    ...wellControlState.UiState,
                    selectedHistoryIndex: undefined,
                    selectedRunMode: runMode,
                    wellControlConfirmation: false,
                    wellControlModalView: "Control",
                },
                smartTimerState: newSmartTimerState,
                useSmartSettingOverride: true,
            })
        }
    }

    const setWellControlStateToError = () => {
        setWellControlState({
            ...wellControlState,
            UiState: {
                ...wellControlState.UiState,
                mutationError: true,
            },
        })
    }

    const toogleUseSmartSettingOverride = (toggleStatus: boolean) => {
        setWellControlState({
            ...wellControlState,
            useSmartSettingOverride: toggleStatus,
        })
    }

    // Context Values
    const contextValues = {
        handleWellControlSettingChange,
        handleRunModeChange,
        handleWellControlTimerChange,
        handleSmartTimerChange,
        handleApplyRunModeChange,
        handleExitSubmitRunModeChange: handleExitSubmitControlSettingChange,
        handleSelectHistoryChange,
        handleReasonForControlChange,
        handleApplyControlSettingHistory,
        handleApplyControlSettingEdit,
        setWellControlStateOnInitialQuery,
        setWellControlStateToError,
        handleWellControlTimerBlur,
        handleSmartTimerBlur,
        toogleUseSmartSettingOverride,
        wellControlState,
    }

    return (
        <WellControlsContext.Provider value={contextValues}>
            {children}
        </WellControlsContext.Provider>
    )
}

// use context hook
const useWellControlsContext = () => {
    const context = useContext(WellControlsContext)

    if (context === undefined) {
        throw new Error(
            "WellControlsContext must be used within a child  WellControlsProvider"
        )
    }

    return context
}

export { WellControlsProvider, useWellControlsContext }
